<template>
  <div>
    <div class="air__utils__heading">
      <h5>{{resourceName}}</h5>
    </div>
    <my-breadcrumbs :routes="routes"/>
    <!--    Enlaces-->
    <hr class="bg-primary">
    <br>

      <div class="wrapper wrapper-content">
        <div class="col-lg-12">

          <div class="row justify-content-center mt-4">

            <div class="col-xl-3 col-lg-4 col-md-12">
              <div class="card">
                <div class="card-title">
                  <h1 class="text-block mt-5 mb-4 text-center">
                    <router-link :to="{ name: 'excel_download'}">
                      <b-iconstack font-scale="3" variant="primary">
                        <b-icon stacked icon="cloud-download-fill" />
                      </b-iconstack>
                    </router-link>
                  </h1>
                </div>
                <div class="card-body text-center">
                  <router-link :to="{ name: 'excel_download'}">
                    <h5 class="text-block text-secondary">Descarga de Formatos</h5>
                  </router-link>
                </div>
              </div>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-12">
              <div class="card">
                <div class="card-title">
                  <h1 class="text-block mt-5 mb-4 text-center">
                    <router-link :to="{ name: ''}">
                      <b-iconstack font-scale="3" variant="primary">
                        <b-icon stacked icon="cloud-upload-fill" />
                      </b-iconstack>
                    </router-link>
                  </h1>
                </div>
                <div class="card-body text-center">
                  <router-link :to="{ name: ''}">
                    <h5 class="text-block text-secondary">Carga de Batch Input</h5>
                  </router-link>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

    <!--    Enlaces-->

  </div>
</template>

<script>
export default {
  // name: 'menuExcelForm',
  components: {
  },
  computed: {
  },
  data() {
    return {
      // Contants
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Menú Excel',
          aIcon: '',
        },
      ],
      resource: '',
      resourceName: 'Excel',
      searchInput: null,
      // Constants
      // actualService: {},
    }
  },
  methods: {

  },
}
</script>

<style scoped>
.border-bottom {
  border-width:3px !important;
}
</style>
